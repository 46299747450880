
import {
    Component,
    OnInit,
    OnDestroy,
    Input,
    Inject,
    ViewChild,
    Output,
    EventEmitter
  } from '@angular/core';

import { Subject } from 'rxjs';
import { MapInfoWindow } from "@angular/google-maps";
import { GetCoordinatesService } from '@app/tracking/container/tracking/GetCoordinatesService.service'
import { GeocodeService } from '@app/shared'

@Component({
    selector: 'app-vehicle-location',
    templateUrl: './vehicle-location.component.html',
    styleUrls: ['vehicle-location.component.scss'],
    standalone: false
})

export class VehicleLocation implements OnInit, OnDestroy {
    @ViewChild('map') mapElement
    @ViewChild(MapInfoWindow, { static: false }) info;

    @Output()
    onClose = new EventEmitter<{}>()

    destroyed$ = new Subject<boolean>();
    map;
    @Input()
    location
    geocoder
    coordinates
    @Input()
    status
    @Input()
    address
    @Input()
    refreshTime

    infoWindowOptions: google.maps.InfoWindowOptions = {
      pixelOffset: new google.maps.Size(0, -30)
    };

    constructor(
      public geocodeService: GeocodeService,
      @Inject(GetCoordinatesService) private getCoordinatesService: GetCoordinatesService
    ) {}

    ngOnInit() {
      this.getCoordinatesService.getLatLng$.subscribe(() => this.eventMapListener());
      this.geocoder = new google.maps.Geocoder();
    }

    eventMapListener() {
      let map = this.mapElement.googleMap
        let bounds = map.getBounds();
        let sw = bounds.getSouthWest();
        let ne = bounds.getNorthEast();
        
        let left = sw.lng();
        let down = sw.lat();
        let right = ne.lng();
        let up = ne.lat();
  
        this.coordinates = {
          'lng1': left, 'lat1': down, 'lng2': right, 'lat2': up
        }
    }

    ngOnDestroy() {
      this.destroyed$.next(true);
      this.destroyed$.complete();
    }
}