import { Component, Input, ChangeDetectionStrategy } from '@angular/core';
@Component({
    selector: 'app-claim-history',
    changeDetection: ChangeDetectionStrategy.OnPush,
    templateUrl: './claim-history.component.html',
    styles: [],
    standalone: false
})
export class ClaimHistoryComponent {
  @Input() history;
}
