import {
  Component,
  Input,
  Output,
  ChangeDetectionStrategy,
  EventEmitter,
  OnInit
} from '@angular/core';

import { UntypedFormBuilder, UntypedFormControl } from '@angular/forms';
@Component({
    selector: 'app-claim-advance-filters',
    changeDetection: ChangeDetectionStrategy.OnPush,
    templateUrl: './advance-filters.component.html',
    styleUrls: ['./advance-filters.component.scss'],
    standalone: false
})
export class AdvanceFiltersComponent implements OnInit {
  @Output()
  onUpdate = new EventEmitter<any>();
  @Output()
  onSet = new EventEmitter<any>();
  @Output()
  onReset = new EventEmitter<any>();
  @Output()
  newClaim = new EventEmitter<any>();
  @Input()
  filters;
  @Input()
  loading;
  @Input()
  sources;
  form;
  @Input()
  users;
  
  isActiveAssignees=true

  constructor(private fb: UntypedFormBuilder) {}

  ngOnInit(): void {
    this.form = this.fb.group({
      carrierId: new UntypedFormControl('5bdb6d6dc2cb5b6f1a8173c8'),
      driverId: new UntypedFormControl(null)
    });
    this.onSubmit();
  }

  addClaim() {
    this.newClaim.emit();
  }

  clearAll() {
    const fields = ['carrier', 'driver', 'claimant'];
    for (const fieldPrefix of fields) {
      const fieldValue = document.querySelector('#typeahead-http-' + fieldPrefix) as HTMLInputElement;
      if (fieldValue != null) {
        fieldValue.value = '';
      }
    }

    this.onReset.emit({
      page: 1
    });
  }

  setValue = (field, value) => {
    if (this.form && this.form.controls[field]) {
      this.form.controls[field].setValue(value);
    }
  };

  onSubmit() {
    if (this.form.invalid) {
      Object.keys(this.form.controls).forEach(field => {
        const control = this.form.get(field);
        control.markAsTouched({ onlySelf: true });
      });
      return;
    }
    this.onUpdate.emit(this.form.value);
  }
  toggleActiveAssignes(status) {
    this.isActiveAssignees = status
  }

  setUnit(id, name, equipmentType) {
    // this.filters.units = [{ unitId: id, unitName: name }, ...this?.filters?.units?.filter(u=>u.unitId!==id)||[]]
    this.filters.units = [{ unitId: id, unitName: name, equipmentType }, ...this?.filters?.units?.filter(u=>u.unitId!==id)||[]]
    if (this.filters.unitIds) {
      if (this.filters.unitIds?.split(',').includes(id)) {
        return this.filters.unitIds
      } else {
        this.filters.unitIds = this.filters.unitIds + ',' + id 
      }
    } else {
      this.filters.unitIds = id
    }
    
    this.onSet.emit({unitIds: this.filters.unitIds})
  }

  // setUnit(id, name, equipmentType) {
  //   this.filters.units = [{ unitId: id, unitName: name, equipmentType }, ...this?.filters?.units?.filter(u=>u.unitId!==id)||[]]
  //   this.filters.unitId = `${id}${this.filters.unitId ? ','+this.filters.unitId : ''}`
  //   this.onFilterChange.emit({unitId: this.filters.unitId})
  // }


  removeUnit(id){
    this.filters.units = this.filters.units.filter(u=>u.unitId!==id)
    this.filters.unitIds = this.filters.unitIds?.split(',').filter(unitId=>unitId!==id).join(',')
    this.onSet.emit({unitIds: this.filters.unitIds})
  }

}
